<template>
  <article class="faq-column">
    <h2 id="pricing-faq-heading">Frequently asked questions about payment and membership</h2>
    <accordion header-id="pricing-faq-heading">
      <accordion-item title="Which payment methods do you accept?">
        <span>Electrify America accepts the following payment methods:</span>
        <ul>
          <li>Visa, MasterCard, American Express, and Discover credit cards</li>
          <li>Visa and MasterCard debit cards</li>
          <li>Apple, Google, and Samsung Pay</li>
        </ul>
      </accordion-item>
      <accordion-item title="How is pricing determined?">
        <p>
          Depending on your location, you pay to charge either by the kWh or by the minute. In per-minute locations, the
          maximum amount of power your vehicle can accept (which your vehicle communicates to the charger at the
          beginning of your charging session) determines your power level and price.
        </p>
      </accordion-item>
      <accordion-item title="In per-minute locations, how does power level pricing work?">
        <p>
          When you plug in to charge, your vehicle tells the charger the maximum power it can accept. Based on that
          communication, your vehicle will be assigned to the corresponding power level offered by the charger.
        </p>
        <p>
          Your charging speed may fluctuate due to factors like weather, age of your battery, and how full your battery
          is (your state of charge). The per-minute price stays the same for the entire session, regardless of how the
          charging speed may vary.
        </p>
      </accordion-item>
      <accordion-item title="In per-kWh locations, how does pricing work?">
        <p>
          Where feasible, Electrify America has transitioned to kWh-based pricing. In these locations, you pay for the
          energy your vehicle receives, regardless of temperature, your battery’s capacity, the time it takes for your
          vehicle to charge, or other variable factors.
        </p>
      </accordion-item>
      <accordion-item title="How do I pay for charging and for the Pass+ subscription fee?">
        <p>
          First, sign up for Electrify America’s Pass or Pass+ membership in the mobile app. Then, follow the prompts to
          add your credit card to your account and select an amount to automatically pre-load to your Electrify America
          wallet balance.
        </p>
        <p>
          The first wallet auto-fill occurs the first time you charge your vehicle at an Electrify America charging
          station after signing up. Every time your balance falls to or below the minimum wallet threshold of $5, the
          wallet will be loaded again with the amount you selected.
        </p>
        <p>
          The cost for charging, idling, and any applicable taxes will be deducted from your wallet balance at the end
          of your charging session.
        </p>
        <p>
          If you’re a Pass+ member, your monthly subscription fee will be charged to your credit or debit card when you
          sign up, and deducted from your wallet balance monthly thereafter.
        </p>
      </accordion-item>
      <accordion-item title="How do I change my membership plan?">
        <p>
          To change membership plans, visit “Plan details'' in the Electrify America app. Please note that a change from
          Pass+ to Pass, will not be reflected until the next billing cycle. Because of this, your Electrify America
          Pass+ plan will remain active until the end of the current billing cycle.
        </p>
      </accordion-item>
      <accordion-item title="How do I pay with my membership pass?">
        <div class="accordion-with-image">
          <section>
            <p>
              First, access your digital membership pass in your mobile wallet*. Then tap your phone against the
              contactless reader on the charger.
            </p>
            <p>*NFC may need to be enabled in your phone’s settings.</p>
          </section>
          <img
            loading="lazy"
            src="@/assets/images/Pricing/tap-here-to-pay@2x.png"
            alt="Members: Tap here to pay with plan"
          />
        </div>
      </accordion-item>
      <accordion-item title="What happens if my Electrify America mobile app wallet runs out of money?">
        <p>
          If your payment method is declined during a charging session, please update your payment method on your
          account to allow Electrify America to auto-fill your wallet so that vehicle charging can continue.
        </p>
        <p>If the payment method is not updated, charging will stop when your wallet balance reaches $0.</p>
      </accordion-item>
      <accordion-item title="How do I switch from one membership type to another?">
        <p>In the app, go to “Plan details” and follow the prompts to upgrade to Pass+ or to change plan types.</p>
        <p>
          Please note that a request to change from Pass+ to Pass membership will go into effect on the first day of the
          next billing cycle, which coincides with the monthly anniversary of your sign-up date.
        </p>
      </accordion-item>
      <accordion-item title="How do I delete my account?">
        <p>Cancel your membership anytime in the app by navigating to your profile and selecting “Delete Account.”</p>
      </accordion-item>
      <accordion-item
        title="Why is EV charging priced on a per-kWh basis in some states and on a per-minute basis in others?"
      >
        <p>
          Pricing on a per-minute basis means pricing based on <u>how long</u> the charger was delivering energy to the
          EV. Pricing on a per-kWh basis means pricing based on <u>how much</u> energy the charger delivered to the EV.
          We believe pricing on a per-kWh basis offers a fair and consistent charging experience to EV drivers. We are
          working with regulators to follow the various processes that are required for us to provide EV charging on a
          per-kWh basis. This is an ongoing process and we look forward to further developments that may allow us to
          provide EV charging on a per-kWh basis throughout the rest of the states.
        </p>
      </accordion-item>
      <accordion-item
        title="How do I find out if EV charging at my favorite charging location is priced on a per-kWh basis or per-minute basis?"
      >
        <p>
          Our pricing varies by station. You can check out any of our stations in the
          <router-link class="link" :to="{ name: 'mobile-app-en' }">Electrify America app</router-link>
          to see pricing as well! To view pricing in the app, select the station from the map and scroll to the bottom
          of the page to view pricing for all chargers. Check the pricing again when you arrive at the charger. It will
          be displayed on the screen when you begin your session.
        </p>
      </accordion-item>
      <accordion-item title="Does this new pricing change my Premium Offer plan?">
        <p>
          Premium Offer plans that include complimentary charging aren’t affected by these changes. Once your plan
          expires or you exceed your plan benefits for a session, the remainder of your charging will automatically be
          billed at our Pass plan pricing. You can choose to enroll in Pass+ for additional savings.
        </p>
      </accordion-item>
      <accordion-item title="What is the cost to charge in Canada?">
        <p>
          For more information on Canada Charging, please visit
          <a class="link" href="https://www.electrify-canada.ca/" rel="noopener" target="_blank">Electrify Canada</a> or
          for Canadian Pricing, please visit
          <a class="link" href="https://www.electrify-canada.ca/pricing/" rel="noopener" target="_blank"
            >Electrify Canada Pricing & Plans</a
          >
          .
        </p>
      </accordion-item>
    </accordion>
  </article>
</template>

<script>
import Accordion from './Accordion/Accordion.vue';
import AccordionItem from './Accordion/AccordionItem.vue';

export default {
  name: 'faqs',
  jsonld: {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Which payment methods do you accept?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Electrify America accepts Visa and MasterCard credit cards and debit cards, as well as American Express.',
        },
      },
      {
        '@type': 'Question',
        name: 'How is pricing determined?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Depending on your location, you pay to charge either by the kWh or by the minute. In per-minute locations, the maximum amount of power your vehicle can accept (which your vehicle communicates to the charger at the beginning of your charging session) determines your power level and price.',
        },
      },
      {
        '@type': 'Question',
        name: 'In per-minute locations, how does power level pricing work?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When you plug in to charge, your vehicle tells the charger the maximum power it can accept. Based on that communication, your vehicle will be assigned to the corresponding power level offered by the charger.<br>Your charging speed may fluctuate due to factors like weather, age of your battery, and how full your battery is (your state of charge). The per-minute price stays the same for the entire session, regardless of how the charging speed may vary.',
        },
      },
      {
        '@type': 'Question',
        name: 'In per-kWh locations, how does pricing work?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Where feasible, Electrify America has transitioned to kWh-based pricing. In these locations, you pay for the energy your vehicle receives, regardless of temperature, your battery’s capacity, the time it takes for your vehicle to charge, or other variable factors.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I pay for charging and for the Pass+ subscription fee?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When you sign up for Electrify America Pass or Pass+ in the mobile app, follow the prompts to add your credit card to your account.<br>When you sign up for Electrify America Pass or Pass+ membership and add your credit card to your account, you will select an amount to automatically pre-load to your Electrify America wallet balance.<br>The first wallet auto-fill occurs the first time you charge your vehicle at an Electrify America charging station after signing up. Every time your balance falls to or below the minimum wallet threshold of $5, the wallet will be loaded again with the amount you selected.<br>The cost for charging, idling, and any applicable taxes will be deducted from your wallet balance at the end of your charging session.<br>If you’re a Pass+ member, your monthly subscription fee will be charged to your credit or debit card when you sign up, and deducted from your wallet balance monthly thereafter.',
        },
      },
      {
        '@type': 'Question',
        name: 'What happens if my Electrify America mobile app wallet runs out of money?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If your payment method is declined during a charging session, please update your payment method on your account to allow Electrify America to auto-fill your wallet so that vehicle charging can continue.<br>If the payment method is not updated, charging will stop when your wallet balance reaches $0.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I switch from one membership type to another?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In the app, go to “Plan details” and follow the prompts to upgrade to Pass+ or to change plan types.<br>Please note that a request to change from Pass+ to Pass membership will go into effect on the first day of the next billing cycle, which coincides with the monthly anniversary of your sign-up date.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I delete my account?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Cancel your membership anytime in the app by navigating to your profile and selecting Delete Account.',
        },
      },
      {
        '@type': 'Question',
        name: 'Why is EV charging priced on a per-kWh basis in some states and on a per-minute basis in others?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Pricing on a per-minute basis means pricing based on <ul>how long</ul> the charger was delivering energy to the EV. Pricing on a per-kWh basis means pricing based on <ul>how much</ul> energy the charger delivered to the EV. We believe pricing on a per-kWh basis offers a fair and consistent charging experience to EV drivers. We are working with regulators to follow the various processes that are required for us to provide EV charging on a per-kWh basis. This is an ongoing process and we look forward to further developments that may allow us to provide EV charging on a per-kWh basis throughout the rest of the states.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I find out if EV charging at my favorite charging location is priced on a per-kWh basis or per-minute basis?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Our pricing varies state by state. The Electrify America website shows each state’s prices based on the charger location, and you can check out any of our stations in the <a href=https://www.electrifyamerica.com/mobile-app/>Electrify America app</a> to see pricing as well! To view pricing in the app, select the station from the map and scroll to the bottom of the page to view pricing for all chargers.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I find out in which states Electrify America offers EV charging on a per-kWh basis or on a per-minute basis?',
        acceptedAnswer: { '@type': 'Answer', text: 'See above.' },
      },
      {
        '@type': 'Question',
        name: 'How is the new pricing structure better than your old pricing structure?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Electrify America is excited to introduce pricing for EV charging on a per-kilowatt-hour basis in many states throughout the US. This means drivers will now pay for <ul>how much</ul> energy is delivered from the charger to their EV instead of <ul>how long</ul> the charger delivered energy to their EV during their charging session.<br>Where we do provide EV charging on a per-minute basis, we have simplified our pricing. High-power chargers will have only two power levels instead of three. The power level of your session is determined by the rate of charge your EV communicates to the charger. The price you will pay per-minute is displayed on the charger after the session begins.<br>Consider enrolling in our Pass+ plan, which saves you up to 25% on charging!',
        },
      },
      {
        '@type': 'Question',
        name: 'Does this new pricing change my Premium Offer plan?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Premium Offer plans that include complimentary charging aren’t affected by these changes. Once your plan expires or you exceed your plan benefits for a session, the remainder of your charging will automatically be billed at our Pass plan pricing. You can choose to enroll in Pass+ for additional savings.',
        },
      },
      {
        '@type': 'Question',
        name: 'What happened to the Hyundai Select and Kia Select plans?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We introduced the Hyundai Select and Kia Select plans to give these drivers more competitive pricing. Our new pricing gives Hyundai and Kia drivers access to the same great pricing all other compatible EVs have on our network, which is even more competitive than the Hyundai Select and Kia Select plans.',
        },
      },
      {
        '@type': 'Question',
        name: 'If I am a Hyundai Select or Kia Select driver, do I need to enroll in Pass+ to save on charging?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If you are a Hyundai Select or Kia Select driver, that plan will automatically be removed from your account on 09/16/2020. At that point you can continue to charge at the new pricing using the Pass plan, or any other plan on your account. If you want to save on every minute or kWh of charging (depending on where you’re charging your EV), can enroll in our Pass+ plan.',
        },
      },
      {
        '@type': 'Question',
        name: 'When does the new pricing plan change?',
        acceptedAnswer: { '@type': 'Answer', text: 'September 16, 2020' },
      },
      {
        '@type': 'Question',
        name: 'Does this affect Canada pricing?',
        acceptedAnswer: { '@type': 'Answer', text: 'Not at this time.' },
      },
      {
        '@type': 'Question',
        name: 'If I am an existing Pass+ member, do I need to do anything to get the new pricing plan?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No, your plan will automatically be updated with the new pricing.',
        },
      },
    ],
  },
  components: {
    Accordion,
    AccordionItem,
  },
};
</script>

<style scoped lang="scss">
.accordion-with-image {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  img {
    max-width: 225px;
    height: auto;
  }
  @media (min-width: 750px) {
    flex-direction: row;
  }
}
</style>
