<template>
  <div class="pricing" :class="{ 'l-webscreens': $route.name.includes('webscreens') }">
    <Hero heading="Pricing and Plans for EV Charging" :bgColor="false">
      <template slot="image">
        <img
          v-if="!isPhone"
          src="@/assets/images/Pricing/pricing-hero.webp"
          alt="Closeup of a person putting a credit card into a card reader at an Electrify America charging station."
        />
        <img
          v-if="isPhone"
          src="@/assets/images/Pricing/pricing-hero-mobile.png"
          alt="Closeup of a person putting a credit card into a card reader at an Electrify America charging station."
        />
      </template>
    </Hero>
    <section class="upsell">
      <img class="bolt" src="@/assets/images/Pricing/electric-bolt.webp" alt="" v-if="!isBig" />
      <p>Use the Electrify America app to see real-time pricing</p>
      <div class="app-icon">
        <a href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8" rel="noopener" target="_blank">
          <img
            loading="lazy"
            :src="require('@/assets/images/LocateCharger/app-store.webp')"
            alt="Download on the App Store"
          /> </a
        ><br />
        <a
          href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
          rel="noopener"
          target="_blank"
        >
          <img loading="lazy" :src="require('@/assets/images/LocateCharger/play.webp')" alt="Get it on Google Play" />
        </a>
      </div>
    </section>
    <div class="wrapper">
      <Card :bgColor="true" heading="Pricing Information" headlineLevel="h2" :maxWidth="1200" :isReverse="isPhone">
        <template #image>
          <picture>
            <source
              media="(min-width: 993px)"
              srcset="@/assets/images/Pricing/pricing-info.webp, @/assets/images/Pricing/pricing-info.webp 2x"
            />
            <img
              srcset="
                @/assets/images/Pricing/pricing-info-mobile.webp,
                @/assets/images/Pricing/pricing-info-mobile@2x.webp 2x
              "
              alt=""
            />
          </picture>
        </template>
        <p>
          Pricing for DC fast charging is determined by charger location, your plan, and, for per-minute locations, the
          maximum power level your vehicle can accept.<strong>
            Real-time pricing is available in the app or at the charger.</strong
          >
        </p>
        <span>In the app:</span>
        <ul>
          <li>To view pricing in the app, select the station from the map</li>
        </ul>
        <span>At the charger:</span>
        <ul>
          <li>
            View pricing on the charger screen at any station.<strong>
              Idle fees may be imposed for parking time after your charging session has ended.</strong
            >
          </li>
        </ul>
      </Card>
      <div class="pricing-disclaimer">
        <p>
          Please refer to the charger screen for the most up-to-date information concerning pricing, power levels, and
          idle fees for your session. You can also view these details in your Electrify America app.
        </p>
      </div>
    </div>
    <section class="charger">
      <HeroWithContentOverlay :overlayBG="false" :darkBG="false">
        <template #image>
          <img loading="lazy" v-if="!isPhone" src="@/assets/images/Pricing/pricing-banner.webp" alt="" />
          <img
            loading="lazy"
            v-if="isPhone"
            class="mobile-img"
            src="@/assets/images/Pricing/pricing-banner-mobile.png"
            alt=""
          />
        </template>

        <div class="content">
          <div class="title">
            <img loading="lazy" class="bolt" src="@/assets/images/Global/bolt.png" alt="" />
            <router-link class="button-link" :to="{ name: 'locate-charger-en' }"
              >Find a charger <arrow class="arrow"
            /></router-link>
          </div>
          <h2 class="header">We’re opening new stations all the time!</h2>
          <p>Find your nearest station on our charger locator map.</p>
        </div>
      </HeroWithContentOverlay>
    </section>
    <section class="ways-to-pay">
      <div class="container">
        <h2 class="header">Ways to pay</h2>
        <div class="payments">
          <div class="payment">
            <div class="left">
              <h3 class="sub-header headline2">Guests</h3>
              <p class="how">Credit or debit card</p>
              <p :class="{ small: isSmall }">
                Swipe or tap your card on the<br v-if="!isSmall" />
                credit card reader at the charger
              </p>
            </div>
            <img loading="lazy" class="card" src="@/assets/images/Pricing/Card.webp" alt="Credit card" />
          </div>
          <div class="payment">
            <div class="left">
              <h3 class="sub-header headline2">Pass or Pass+ Members</h3>
              <p class="how">Pay contact-free with the app</p>
              <p>
                Just add a card to your app<br />
                profile to pay seamlessly
              </p>
              <router-link
                aria-label="Learn more about pass or pass+ membership using the Electrify America app"
                class="button-link"
                :to="{ name: 'mobile-app-en' }"
                >LEARN MORE<arrow class="arrow"></arrow
              ></router-link>
            </div>
            <img
              loading="lazy"
              class="phone"
              src="@/assets/images/Pricing/Phone.webp"
              alt="Smartphone showing a digital card"
            />
          </div>
        </div>
      </div>
    </section>

    <HeroWithContentOverlay :mobileResponsive="isPhone ? 'above' : 'overlay'" :overlayBG="false">
      <template #image>
        <img
          loading="lazy"
          v-if="isPhone"
          src="@/assets/images/Pricing/premium-offer-mobile.png"
          alt="Close-up of a person making a contactless payment at an Electrify America charging station"
        />
        <img
          loading="lazy"
          v-else
          src="@/assets/images/Pricing/premium-offer.png"
          alt="Closeup of a person making a contactless payment at an Electrify America charging station"
        />
      </template>
      <h2>Get Premium Offers in the app</h2>
      <p>Your vehicle could qualify for a Premium Offer. Download the Electrify America mobile app to see all plans.</p>
      <router-link class="button-link" :to="{ name: 'mobile-app-en' }"
        >GET THE APP <arrow class="arrow"></arrow
      ></router-link>
    </HeroWithContentOverlay>

    <section class="faq">
      <div class="container">
        <pricingfaq />
      </div>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';
import arrow from '@/assets/images/Global/arrow.svg';
import pricingfaq from '@/components/PricingFaq.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import Card from '@/components/Card/Card.vue';

export default {
  name: 'pricing',
  components: {
    pricingfaq,
    arrow,
    Hero,
    HeroWithContentOverlay,
    Card,
  },
  metaInfo: {
    title: 'Pricing and Plans for EV Charging | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America aims to keep prices low while providing more electric vehicle chargers, with faster speeds, in better locations across the U.S. Learn about our pricing.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/pricing/' }],
  },
  data() {
    return {
      selectedState: 'California',
      country: 'US',
      currentPrices: false,
      pass: [],
      passPlus: [],
      noPricesAvailable: false,
      error: null,
      axiosCancel: null,
      staticPricing: null,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.below(950);
    },
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isPhoneXL() {
      return this.$resize && this.$mq.below(849);
    },
    isSmall() {
      return this.$resize && this.$mq.below(345);
    },
    idle() {
      return this.currentPrices.idle;
    },
    isChrome() {
      return window.navigator.vendor === 'Google Inc.';
    },
  },
  methods: {
    getPricing(stateName) {
      this.currentPrices = null;
      this.noPricesAvailable = false;
      this.staticPricing = null;

      this.currentPrices = false;
      this.noPricesAvailable = false;

      /**
       * Pricing was changed to display fully static data from `src/constants/staticPricing.js`
       * on 20221007. This conditional allows us to also test live API pricing data by adding
       * ?pricing=api to the URL
       */
      let pricingGetter = 'locations/getStateStaticPricing';
      if (this.$route.query.pricing && this.$route.query.pricing === 'api') {
        pricingGetter = 'locations/getStatePricing';
      }

      this.$store.getters[pricingGetter](stateName).then((statePricing) => {
        if (statePricing === null) {
          this.error = `There are currently no chargers available in this province/state. Please select a different province/state.`;
          this.currentPrices = '';
          this.noPricesAvailable = true;
          this.staticPricing = true;
        } else {
          this.currentPrices = statePricing.passPricing;
          this.pass = statePricing.passPricing.pass;
          this.passPlus = statePricing.passPricing.pass_plus;
        }
      });
    },
  },
  watch: {
    selectedState(newState) {
      this.getPricing(newState);
    },
  },
  mounted() {
    this.getPricing(this.selectedState);

    setTimeout(() => {
      AOS.refresh();
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.pricing {
  .router-link {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 600;
  }

  .hero-with-overlay {
    margin: 55px auto;

    h2 {
      font-size: 32px;
      @media (min-width: 850px) {
        font-size: 48px;
      }
    }
    img {
      margin-top: 32px;
      @media (min-width: 750px) {
        margin-top: 0;
      }
    }
  }

  .loading {
    @keyframes loading {
      to {
        transform: rotateZ(360deg);
      }
    }
    display: flex;
    justify-content: center;
    span {
      display: block;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: $c-primary;
      border-bottom-color: $c-primary;
      animation: loading 850ms ease-in-out infinite;
    }
  }

  .container {
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .header {
    margin-top: 60px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: 0.64px;
    color: $c-primary;
    @media only screen and (min-width: 750px) {
      font-size: 32px;
    }
  }

  .header-title {
    margin: 0;
    font-weight: $fw-light;
    font-size: 30px;
    line-height: 0.88;
    letter-spacing: 1.6px;
    color: $c-primary;
    @media only screen and (min-width: 750px) {
      font-size: 80px;
    }
  }

  .sub-header {
    font-size: 20px;
    margin: 0;
    color: $c-primary-background;
  }

  .detail {
    font-size: 14px;
    margin-bottom: 8px;
    letter-spacing: 0.7px;
  }

  .charger {
    padding: 0;
    @media only screen and (min-width: 750px) {
      padding: 70px 0;
    }

    .sub-container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 40px 0 60px 0;
      @media only screen and (min-width: 750px) {
        padding: 0;
      }
      @media only screen and (min-width: 950px) {
        max-width: 700px;
      }
    }

    img {
      width: 100%;
    }

    .header {
      margin: 0px 0 24px;
      text-align: center;
      @media only screen and (min-width: 750px) {
      }
    }

    select::-ms-expand {
      display: none;
    }

    .state-code {
      position: relative;
      border: solid 1px #b8b8b8;
      font-weight: $fw-medium;
      width: 90%;
      height: 58px;
      padding: 16px 34px;
      border-radius: 100px;
      align-self: center;
      background: url('~@/assets/images/Global/carat.svg?external') no-repeat center right 20px;
      background-size: 18px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      color: #424b56;
      font-size: 20px;
      margin-bottom: 40px;

      @media only screen and (min-width: 750px) {
        width: 366px;
        background-position-x: 315px;
        background-position-y: 24px;
      }
    }

    .col {
      flex-direction: column !important;
    }

    .rates {
      box-sizing: border-box;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (min-width: 750px) {
        flex-direction: row;
      }

      .header {
        margin: 0;
        text-align: center;
        @media only screen and (min-width: 750px) {
          margin: 16px 0;
        }
      }

      .staticPricing {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 60px;
        @media only screen and (min-width: 750px) {
          flex-direction: row;
        }
      }

      .staticPricing-table {
        flex: 1;
      }

      .staticPricing-table:nth-child(odd) {
        margin-bottom: 60px;
        @media only screen and (min-width: 750px) {
          margin-right: 60px;
          margin-bottom: 0;
        }
      }

      .error {
        text-align: center;
        justify-self: center;
        align-self: center;
        width: 90%;
        @media only screen and (min-width: 750px) {
          width: 55%;
        }
      }

      .table {
        margin-bottom: 60px;
        @media only screen and (min-width: 750px) {
          margin-right: 60px;
          margin-bottom: 0px;
        }
      }

      .table:last-child {
        margin-right: 0;
      }

      .pass {
        flex: 1;
        position: relative;
        align-items: center;
        border-radius: 8px;
        border: solid 1px #c4c4c4;
        @media (min-width: 750px) {
          max-width: 320px;
        }
      }

      .save {
        text-align: center;
        position: absolute;
        font-size: 14px;
        font-weight: 600;
        color: $c-primary;
        top: -30px;
        width: 100%;
      }

      .headline {
        padding: 8px 16px;
        text-align: center;
        color: $c-primary-background;
        border-radius: 7px 7px 0 0;
      }

      .guest {
        background-color: $c-primary-lighter;
      }

      .pass-plus {
        background-color: $c-primary;
      }

      .rate {
        padding: 8px 32px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        @media only screen and (min-width: 750px) {
          font-size: 24px;
        }
        p {
          margin: 0;
        }
        span {
          font-size: 16px;
          font-weight: normal;
        }

        .rate-span {
          font-size: 24px;
          font-weight: 600;
          display: inline;
          margin: 0;
        }

        .rate-container {
          display: flex;
          flex-direction: column-reverse;
          dl {
            margin: 0;
          }
        }
      }
    }

    .pricing-detail {
      background-color: $c-secondary-background;
      @media only screen and (min-width: 750px) {
        background-color: $c-primary-background;
      }
    }

    hr {
      margin: 40px 0 16px;
    }

    .content {
      .title {
        display: flex;
        align-items: center;

        .bolt {
          margin: 0;
          margin-right: 8px;
          width: 20px;
          height: 35px;
        }
      }

      .header {
        margin: 30px 0 8px;
        font-size: 32px;
        text-align: left;
        font-weight: normal;
      }

      p {
        font-weight: $fw-medium;
      }
    }
  }

  .ways-to-pay {
    padding: 40px 0 120px;
    background-color: $c-secondary-background;
    @media only screen and (min-width: 750px) {
      padding: 80px 0 140px;
    }

    .header {
      margin: 0 0 40px 0;
      @media only screen and (min-width: 750px) {
        margin: 0 0 70px 0;
      }
    }

    .small {
      width: 65%;
    }

    .payments {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 750px) {
        flex-direction: row;
      }
    }

    .payment:first-child {
      margin-right: 0;
      margin-bottom: 60px;
      @media only screen and (min-width: 750px) {
        margin-right: 60px;
        margin-bottom: 0px;
      }
    }

    .payment {
      flex: 100%;
      display: flex;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;
      padding: 32px;
      background-color: $c-primary-background;
      min-height: 173px;
      @media only screen and (min-width: 1200px) {
        justify-content: flex-start;
        min-height: auto;
      }
    }

    .sub-header {
      margin: 0;
      color: $c-primary;
      @media only screen and (min-width: 900px) {
        margin-bottom: 8px;
        font-size: 24px;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media only screen and (min-width: 750px) {
        max-width: 300px;
      }
      @media only screen and (min-width: 1200px) {
        align-items: flex-start;
        text-align: initial;
      }
    }

    .how {
      font-size: 14px;
      margin-bottom: 16px;
      font-weight: normal;
      @media only screen and (min-width: 345px) {
        font-size: 18px;
      }
      @media only screen and (min-width: 900px) {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 12px;
      margin: 0;
      @media only screen and (min-width: 900px) {
        font-size: 16px;
      }
    }

    .card {
      top: 30px;
      right: -25px;
      bottom: -80px;
      position: absolute;
      width: 150px;
      height: 150px;
      @media only screen and (min-width: 375px) {
        top: 20px;
        bottom: -80px;
        width: 180px;
        height: 180px;
      }
      @media only screen and (min-width: 750px) {
        bottom: -100px;
      }
      @media only screen and (min-width: 950px) {
        top: 45px;
        right: 0;
        width: 190px;
        height: 190px;
      }
      @media only screen and (min-width: 1100px) {
        top: 45px;
        right: 0;
        width: 245px;
        height: 245px;
      }
    }

    .phone {
      bottom: -55px;
      right: -30px;
      position: absolute;
      width: 170px;
      height: 170px;
      @media only screen and (min-width: 375px) {
        width: 180px;
        height: 180px;
      }
      @media only screen and (min-width: 950px) {
        top: 45px;
        right: 0;
        width: 200px;
        height: 200px;
      }
      @media only screen and (min-width: 1100px) {
        top: 45px;
        right: 0;
        width: 245px;
        height: 245px;
      }
    }

    .router-link {
      font-size: 14px;
      @media (min-width: 750px) {
        font-size: 16px;
      }
    }
  }
  .bolt {
    width: 96px;
    height: 96px;
  }

  .upsell {
    p {
      font-weight: $fw-light;
      font-size: 28px;
      padding: 0 20px;
      margin: 0;
    }
    background: $c-secondary-background;
    padding: 40px 60px;
    margin: 40px auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    border-radius: 8px;
    .app-icon {
      img {
        width: 150px;
      }
      img:last-child {
        padding-left: 20px;
      }
      display: flex;
    }
    @media only screen and (max-width: 950px) {
      flex-direction: column;
      background: none;
      margin: 20px auto;
      justify-content: center;
      max-width: 500px;
      h2 {
        font-size: 20px;
        padding: 0;
      }
      .app-icon {
        justify-content: space-between;
        align-content: center;
        margin-top: 20px;
        width: 100%;
        img {
          width: 120px;
        }
        img:last-child {
          padding: 0px;
        }
      }
    }
  }
  .wrapper {
    background: $c-secondary-background;
    max-width: 100%;
  }
  .pricing-disclaimer {
    max-width: 700px;
    margin: auto;
    padding-bottom: 64px;
    p {
      font-size: 14px;
      @media only screen and (max-width: 750px) {
        font-size: 12px;
      }
    }
  }
  .card img {
    max-width: 600px;
  }
}
</style>
